import { Component } from '@angular/core';
import { AuthService, DelegationPecMailService, NewsService } from './core/services';
import { TranslocoLangDetectorService } from '@shared/transloco/transloco-lang-detector.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'supervisore360';

  constructor(
    private readonly authService: AuthService,
    private readonly translocoLangDetectorService: TranslocoLangDetectorService,
    private readonly oauthService: OAuthService,
    private readonly delegationPecMailService: DelegationPecMailService,
    private readonly newsService: NewsService
  ) {
    this.clearSuperNewsIdsIfNotAuthenticated();
    this.configureOauth2();
    this.translocoLangDetectorService.setBrowserLangIfNotSelected();
    this.delegationPecMailService.init();
  }

  private configureOauth2() {
    this.authService.runInitialLoginSequence().then(() => {
      if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
        this.authService.logout();
      }
    });
  }

  private clearSuperNewsIdsIfNotAuthenticated() {
    if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
      this.newsService.resetDisplayedSuperNewsIds();
    }
  }
}
