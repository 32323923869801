import { IApiFilter } from '@/src/app/core/interfaces';
import {
  EApiCallStateKey,
  EApiRequestPartKeys,
} from '@/src/app/core/state/api-call-state/api-call-state.enum';
import {
  selectActiveFilters,
  selectApiCallStateRequestPartByKey,
} from '@/src/app/core/state/api-call-state/api-call-state.selectors';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'aup-list-filters',
  templateUrl: './list-filters.component.html',
  styleUrls: ['./list-filters.component.scss'],
})
export class ListFiltersComponent implements OnInit {
  @Input() apiCallStateKey: EApiCallStateKey;
  @Input() isLoading: boolean;

  @Output() allFiltersReset: EventEmitter<void> = new EventEmitter<void>();

  activeFilters$: Observable<IApiFilter[]>;
  totalItems: number;
  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.activeFilters$ = this.store$.select(selectActiveFilters({ apiCallStateKey: this.apiCallStateKey }));
    this.store$
      .select(
        selectApiCallStateRequestPartByKey({
          apiCallStateKey: this.apiCallStateKey,
          requestPartKey: EApiRequestPartKeys.PAGINATION,
        })
      )
      .subscribe(pagination => {
        this.totalItems = pagination.data.totalItems;
      });
  }
}
