<div *ngIf="data" class="flex flex-col h-full">
  <div class="aup-background-gray px-5 flex items-center justify-between">
    <h2 class="!p-0 w-2/3 md:w-auto font-bold text-lg leading-5" mat-dialog-title>
      <span class="font-bold">{{ data.title | transloco: data.titleParams }}</span>
    </h2>
    <button type="button" (click)="dialogRef.close({ status: EDialogCloseStatus.CANCEL })" mat-icon-button>
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div class="p-6 flex items-center">
    <mat-icon svgIcon="warning" class="mr-6 scale-[200%]"></mat-icon>
    <p [innerHTML]="data.message | transloco: data.messageParams"></p>
  </div>

  <mat-divider></mat-divider>

  <div class="flex !items-start !p-6" mat-dialog-actions>
    <button mat-flat-button color="secondary" (click)="dialogRef.close({ status: EDialogCloseStatus.OK })">
      {{ data.okLabel || 'COMMON.BUTTON.OK' | transloco }}
    </button>
  </div>
</div>
