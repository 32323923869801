import { Component, Input } from '@angular/core';
import { FilterComponent } from '../filter/filter.component';
import { IStatusChip } from '../status-chip/status-chip.interface';

@Component({
  selector: 'aup-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
})
export class StatusFilterComponent extends FilterComponent {
  @Input() statusesMap: Record<string, IStatusChip>;
}
