import { IToastData } from '@/src/app/core/interfaces';
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'aup-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
})
export class ToastMessageComponent {
  messageToDisplay = '';
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IToastData,
    public snackBarRef: MatSnackBarRef<any>
  ) {
    this.messageToDisplay = data.title || '';
    if (data.title && data.message) {
      this.messageToDisplay += ' | ';
    }
    this.messageToDisplay += data.message || '';
  }
}
