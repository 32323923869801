<div class="aup-background-gray px-4 md:pl-10 flex items-center justify-between">
  <h2 class="!p-0 w-2/3 md:w-auto" mat-dialog-title>{{ 'UNSAVED_CHANGES_MODAL.TITLE' | transloco }}</h2>
</div>
<div class="p-6 flex items-center">
  <div class="mr-2"><mat-icon svgIcon="warning"></mat-icon></div>
  <p>
    {{ 'UNSAVED_CHANGES_MODAL.MESSAGE' | transloco }}
  </p>
</div>

<mat-divider></mat-divider>

<div class="!flex !items-start !p-6 flex-col gap-2 sm:flex-row" mat-dialog-actions>
  <button
    mat-flat-button
    (click)="onSaveAndContinue()"
    color="primary"
    class="w-full sm:w-auto"
    cdkFocusInitial
  >
    {{ 'COMMON.BUTTON.SAVE_AND_CONTINUE' | transloco }}
  </button>
  <button mat-flat-button (click)="onDontSaveAndContinue()" class="w-full !ml-0 sm:w-auto" color="secondary">
    {{ 'COMMON.BUTTON.DO_NOT_SAVE_AND_CONTINUE' | transloco }}
  </button>
  <button mat-flat-button (click)="onCancel()" class="w-full !ml-0 sm:w-auto" color="secondary">
    {{ 'COMMON.BUTTON.CANCEL' | transloco }}
  </button>
</div>
