import { IAdvancedFilterDefinition, IAdvancedFiltersConfig, IApiFilter } from '@/src/app/core/interfaces';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AdvancedFiltersBase } from '../advanced-filters-base/advanced-filters-base.component';
import { ApiCallStateActions } from '@/src/app/core/state/api-call-state/api-call-state.actions';
import { EApiRequestPartKeys } from '@/src/app/core/state/api-call-state/api-call-state.enum';
import { EAdvancedFilterType } from '@enums';

@Component({
  selector: 'aup-advanced-filters-main',
  templateUrl: './advanced-filters-main.component.html',
  styleUrls: ['./advanced-filters-main.component.scss'],
})
export class AdvancedFiltersMainComponent extends AdvancedFiltersBase implements OnInit, OnDestroy {
  @Input() set config(config: IAdvancedFiltersConfig) {
    this._config = config;
    this.fillFiltersOptions();
  }
  @Input() filter: IApiFilter;
  @Input() set displayValidationErrors(displayValidationErrors: number) {
    if (displayValidationErrors) {
      this.filterSelect.markAsTouched();
    }
    this._displayValidationErrors = displayValidationErrors;
  }
  @Input() set selectedFiltersProperties(selectedFiltersProperties: string[]) {
    this._selectedFiltersProperties = selectedFiltersProperties;
    this.fillFiltersOptions();
  }
  @Input() isLast: boolean;

  _config: IAdvancedFiltersConfig;
  _selectedFiltersProperties: string[];
  filtersOptions: IAdvancedFilterDefinition[];
  filterSelect: FormControl = new FormControl(null, Validators.required);

  eAdvancedFilterType = EAdvancedFilterType;
  _displayValidationErrors = null;

  ngOnInit(): void {
    if (this.filter?.property) {
      this.filterSelect.setValue(this.filter.property, { emitEvent: false });
    }

    this.subscription.add(
      this.filterSelect.valueChanges.subscribe(value => {
        this.store$.dispatch(
          ApiCallStateActions.updateRequestPartDataSlice({
            apiCallStateKey: this._config.apiCallStateKey,
            requestPartKey: EApiRequestPartKeys.FILTERS,
            dataSliceKey: this.filter.id,
            data: {
              property: value,
              value: null,
              complexValue: null,
              defaultValue: null,
              advancedFilterData: {
                formData: null,
                isValid: false,
                value: null,
                complexValue: null,
              },
            },
          })
        );
      })
    );
  }

  onFilterDelete() {
    if (this.isLast) {
      this.filterSelect.setValue(null);
      this.filterSelect.markAsUntouched();
      return;
    }
    this.store$.dispatch(
      ApiCallStateActions.removeRequestPartDataSlice({
        apiCallStateKey: this._config.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.FILTERS,
        dataSliceKey: this.filter.id,
      })
    );
  }

  private fillFiltersOptions() {
    if (!this._config || this._selectedFiltersProperties === undefined) {
      return;
    }
    this.filtersOptions = Object.entries(this._config.filters)
      .map(([_, definition]) => definition)
      .filter(definition => {
        return (
          !this._selectedFiltersProperties.includes(definition.property) ||
          definition.property === this.filter.property
        );
      });
  }
}
