import { Component, Input } from '@angular/core';
import { AdvancedFiltersFilterItemBase } from '../advanced-filters-filter-item-base/advanced-filters-filter-item-base.component';
import { FormControl, Validators } from '@angular/forms';
import { IAdvancedFilterData } from '@/src/app/core/interfaces';
import { EAdvancedFilterComparator } from '@/src/app/core/enums';

import { dateRangeValidator } from '@/src/app/shared/validators/data-range.validator';

@Component({
  selector: 'aup-advanced-filters-date',
  templateUrl: './advanced-filters-date.component.html',
  styleUrls: ['./advanced-filters-date.component.scss'],
})
export class AdvancedFiltersDateComponent extends AdvancedFiltersFilterItemBase {
  @Input() set fiscalYear(fiscalYear: string) {
    if (!fiscalYear) {
      this.minDate = undefined;
      this.maxDate = undefined;
      return;
    }

    this.minDate = `${fiscalYear}-01-01`;
    this.maxDate = `${fiscalYear}-12-31`;
  }

  minDate: string;
  maxDate: string;

  private isRangeType = null;

  initForm() {
    this.formGroup = this.fb.group({
      type: [null, Validators.required],
      startDate: [null, [Validators.required, dateRangeValidator(this.minDate, this.maxDate)]],
      endDate: [null, [dateRangeValidator(this.minDate, this.maxDate)]],
    });
  }

  getFilterValues(): Partial<IAdvancedFilterData> {
    if (!this.formGroup.valid) {
      return {};
    }
    let value: any = {};

    const { startDate, endDate } = this.formGroup.value;

    if (this.formGroup.value.type === EAdvancedFilterComparator.FROM_TO) {
      value = {
        valueFrom: startDate,
        valueTo: endDate,
      };
    } else {
      value = {
        value: startDate,
      };
    }

    return {
      value: null,
      complexValue: {
        type: this.formGroup.value.type,
        ...value,
      },
    };
  }

  protected override onFormValuesChange(values: any): void {
    if (!values.type || !this.hasTypeChanged(values)) {
      return;
    }

    this.isRangeType = !!(values.type === EAdvancedFilterComparator.FROM_TO);
    const endDateFormControl = this.formGroup.get('endDate') as FormControl;
    if (this.isRangeType) {
      this.setControlRequired(endDateFormControl);
      endDateFormControl.addValidators([dateRangeValidator(this.minDate, this.maxDate)]);
      endDateFormControl.updateValueAndValidity({ emitEvent: false });
    } else {
      this.unsetControlRequired(endDateFormControl);
    }
  }

  private hasTypeChanged(values: any) {
    return (
      this.isRangeType === null ||
      (values.type === EAdvancedFilterComparator.FROM_TO && !this.isRangeType) ||
      (values.type !== EAdvancedFilterComparator.FROM_TO && this.isRangeType)
    );
  }
}
