import { IDialogResult, IDialogSize, IDialogSizeMap } from '@/src/app/core/interfaces';
import { ToastMessagesService } from '@/src/app/core/services';
import { selectBreakpoints } from '@/src/app/core/state/session/session.selectors';
import { IBreakpoints } from '@/src/app/core/state/session/session.state';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'aup-super-dialog-component',
  template: '',
})
export abstract class SuperDialogComponent<T> implements OnInit, OnDestroy {
  abstract sizesMap: IDialogSizeMap;
  protected toastMessagesService: ToastMessagesService;

  content: string;
  store$: Store;
  subscription: Subscription = new Subscription();
  breakpoints$: Observable<IBreakpoints>;
  contentChanged: Subject<boolean> = new Subject<boolean>();

  constructor(
    public injector: Injector,
    public dialogRef: MatDialogRef<T>
  ) {
    this.store$ = injector.get<Store>(Store);
    this.breakpoints$ = this.store$.select(selectBreakpoints);
    this.toastMessagesService = injector.get(ToastMessagesService);
  }

  ngOnInit(): void {
    this.listenForSizeChange();
    this.contentChanged.next(true);
  }

  onClose(result: IDialogResult = undefined) {
    this.dialogRef.close(result);
    this.toastMessagesService.dismiss();
  }

  switchContent(content: string) {
    this.content = content;
    this.contentChanged.next(true);
  }

  private listenForSizeChange() {
    this.subscription.add(
      combineLatest([this.contentChanged, this.breakpoints$]).subscribe(([_, breakpoints]) => {
        const isSmall = breakpoints.isSmall || breakpoints.isXSmall;
        const size: IDialogSize = isSmall
          ? this.sizesMap[this.content].small
          : this.sizesMap[this.content].other;

        this.dialogRef.updateSize(size.width, size.height);
        this.dialogRef.updatePosition();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.contentChanged.complete();
  }
}
