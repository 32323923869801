import { ENewsAction } from '@/src/app/core/enums';
import { INewsAction } from '@/src/app/core/interfaces';
import { News } from '@/src/app/core/models';
import { NewsService } from '@/src/app/core/services';
import { NewsActions } from '@/src/app/core/state/news/news.actions';

import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

/**
 * NewsListDetailComponent
 * @date 9/12/2023 - 8:14:05 PM
 *
 * @export
 * @class NewsListDetailComponent
 * @typedef {NewsListDetailComponent}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'aup-news-list-detail',
  templateUrl: './news-list-detail.component.html',
  styleUrls: ['./news-list-detail.component.scss'],
})
export class NewsListDetailComponent {
  /**
   * Selected news instance
   * @date 9/12/2023 - 8:14:05 PM
   *
   * @type {News}
   */
  @Input() selectedNews: News;

  /**
   * Description placeholder
   * @date 9/12/2023 - 8:14:05 PM
   *
   * @type {*}
   */
  @Output() action: EventEmitter<INewsAction> = new EventEmitter<INewsAction>();

  /**
   * Description placeholder
   * @date 26/09/2023 - 10:02:58
   *
   * @type {News}
   */
  newsDetails: News;
  /**
   * Description placeholder
   * @date 26/09/2023 - 10:02:58
   *
   * @type {Subscription}
   */
  subscription: Subscription;

  /**
   * Creates an instance of NewsListDetailComponent.
   * @date 19/09/2023 - 19:31:40
   *
   * @constructor
   * @param {Store} store$
   * @param {NewsService} newsService
   */
  constructor(
    private store$: Store,
    private newsService: NewsService
  ) {}

  /**
   * NgOnInit
   * @date 9/2/2023 - 4:24:31 PM
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['selectedNews']) {
      return;
    }
    if (
      !changes['selectedNews'].previousValue ||
      changes['selectedNews']?.currentValue.id !== changes['selectedNews']?.previousValue.id
    ) {
      if (!this.selectedNews.isReaded) {
        this.store$.dispatch(NewsActions.markAsReadUnRead({ news: this.selectedNews, read: true }));
      }

      this.newsDetails = undefined;
      // Note cancel prev request in case of fast click
      this.subscription?.unsubscribe();
      this.subscription = new Subscription();
      this.subscription.add(
        this.newsService.getById<News>({ id: this.selectedNews.id }).subscribe(response => {
          this.newsDetails = response.data;
        })
      );
    }
  }

  /**
   * Mark news as unread
   * @date 9/12/2023 - 8:14:05 PM
   */
  onMarkAsUnread() {
    this.action.emit({ news: this.selectedNews, action: ENewsAction.MARK_AS_UNREAD });
  }

  /**
   * Description placeholder
   * @date 19/09/2023 - 18:46:44
   */
  onMarkAsRead() {
    this.action.emit({ news: this.selectedNews, action: ENewsAction.MARK_AS_READ });
  }
}
