<div class="aup-background-gray px-4 md:pl-6 flex items-center justify-between w-full">
  <h2 class="!p-0 w-2/3 md:w-auto" mat-dialog-title>{{ 'ADVANCED_FILTERS.MODAL.TITLE' | transloco }}</h2>
  <button type="button" (click)="onClose()" mat-icon-button>
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="p-6 flex flex-col gap-2 justify-between w-full min-h-[160px] aup-advanced-filters">
  <div class="flex flex-col gap-2">
    <h3 class="!text-sm">{{ 'ADVANCED_FILTERS.MODAL.SUB_TITLE' | transloco }}</h3>
    <div class="flex flex-col gap-2">
      <ng-container *ngFor="let filter of filters$ | async; trackBy: trackByFn; let i = index">
        <aup-advanced-filters-main
          [config]="config"
          [filter]="filter"
          [displayValidationErrors]="displayValidationErrors"
          [selectedFiltersProperties]="selectedFiltersProperties"
          [isLast]="numberOfFilters === 1"
        ></aup-advanced-filters-main>
        <mat-divider class="w-full"></mat-divider>
      </ng-container>
    </div>
  </div>
  <div class="mt-4">
    <button mat-flat-button color="secondary" (click)="onAddFilter()">
      <mat-icon svgIcon="add"></mat-icon>
      {{ 'ADVANCED_FILTERS.BUTTON.ADD_NEW_FILTER' | transloco }}
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<div class="!flex !items-start !p-6 flex-col gap-2 sm:flex-row" mat-dialog-actions>
  <button mat-flat-button (click)="onFilter()" color="primary" class="w-full sm:w-auto" cdkFocusInitial>
    {{ 'ADVANCED_FILTERS.BUTTON.FILTER' | transloco }}
  </button>
</div>
