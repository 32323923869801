import { Component } from '@angular/core';
import { AdvancedFiltersFilterItemBase } from '../advanced-filters-filter-item-base/advanced-filters-filter-item-base.component';
import { FormControl, Validators } from '@angular/forms';
import { IAdvancedFilterData } from '@interfaces';
import { EAdvancedFilterComparator } from '@enums';

@Component({
  selector: 'aup-advanced-filters-number',
  templateUrl: './advanced-filters-number.component.html',
  styleUrls: ['./advanced-filters-number.component.scss'],
})
export class AdvancedFiltersNumberComponent extends AdvancedFiltersFilterItemBase {
  private isRangeType = null;

  initForm() {
    this.formGroup = this.fb.group({
      type: [null, Validators.required],
      value: [null],
      valueFrom: [null],
      valueTo: [null],
    });
  }

  getFilterValues(): Partial<IAdvancedFilterData> {
    if (!this.formGroup.valid) {
      return {};
    }
    let reqValue: any = {};

    const { valueFrom, valueTo, value } = this.formGroup.value;

    if (this.formGroup.value.type === EAdvancedFilterComparator.FROM_TO) {
      reqValue = {
        valueFrom,
        valueTo,
      };
    } else {
      reqValue = {
        value,
      };
    }

    return {
      value: null,
      complexValue: {
        type: this.formGroup.value.type,
        ...reqValue,
      },
    };
  }

  protected override onFormValuesChange(values: any): void {
    if (!values.type || !this.hasTypeChanged(values)) {
      return;
    }

    this.isRangeType = !!(values.type === EAdvancedFilterComparator.FROM_TO);
    const valueControl = this.formGroup.get('value') as FormControl;
    const valueFromControl = this.formGroup.get('valueFrom') as FormControl;
    const valueToControl = this.formGroup.get('valueTo') as FormControl;
    if (this.isRangeType) {
      this.setControlRequired(valueFromControl);
      this.setControlRequired(valueToControl);
      this.unsetControlRequired(valueControl);
    } else {
      this.unsetControlRequired(valueFromControl);
      this.unsetControlRequired(valueToControl);
      this.setControlRequired(valueControl);
    }
  }

  private hasTypeChanged(values: any) {
    return (
      this.isRangeType === null ||
      (values.type === EAdvancedFilterComparator.FROM_TO && !this.isRangeType) ||
      (values.type !== EAdvancedFilterComparator.FROM_TO && this.isRangeType)
    );
  }
}
