import { EBillingJobStatus } from '@/src/app/core/enums';
import { BillingJob } from '@/src/app/core/models';
import { BillingJobsService } from '@/src/app/core/services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aup-job-progress',
  templateUrl: './job-progress.component.html',
  styleUrls: ['./job-progress.component.scss'],
})
export class JobProgressComponent implements OnInit {
  isOpened: boolean = false;
  job: BillingJob;

  eBillingJobStatus = EBillingJobStatus;

  constructor(private readonly billingJobService: BillingJobsService) {}

  onClose() {
    this.isOpened = false;
    this.billingJobService.killCurrentJob();
  }

  ngOnInit(): void {
    this.billingJobService.currentJobInfo$.subscribe(job => {
      this.job = job;
      this.isOpened = true;
    });
    this.billingJobService.init();
  }
}
