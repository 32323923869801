import { Component, inject, Injector, Input, OnInit } from '@angular/core';
import { AdvancedFiltersBase } from '../advanced-filters-base/advanced-filters-base.component';
import { IAdvancedFilterData, IAdvancedFilterDefinition, IApiFilter } from '@/src/app/core/interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { dateNumberComparatorOptions } from '../../data';
import clone from 'just-clone';
import { ApiCallStateActions } from '@/src/app/core/state/api-call-state/api-call-state.actions';
import {
  EApiCallStateKey,
  EApiRequestPartKeys,
} from '@/src/app/core/state/api-call-state/api-call-state.enum';
import { EAdvancedFilterComparator } from '@enums';
import { debounce, timer } from 'rxjs';
import { LanguagesService } from '@/src/app/core/services';

@Component({
  template: '',
})
export abstract class AdvancedFiltersFilterItemBase extends AdvancedFiltersBase implements OnInit {
  @Input() filterDefinition: IAdvancedFilterDefinition;
  @Input() filterData: IApiFilter;
  @Input() apiCallStateKey: EApiCallStateKey;
  @Input() set displayValidationErrors(displayValidationErrors: number) {
    if (displayValidationErrors && this.formGroup) {
      this.formGroup.markAllAsTouched();
    }
  }

  protected abstract initForm();
  protected abstract getFilterValues(): Partial<IAdvancedFilterData>;
  protected onFormValuesChange(values: any) {}

  protected readonly fb: FormBuilder = inject(FormBuilder);
  protected readonly languagesService: LanguagesService = inject(LanguagesService);

  eAdvancedFilterComparator = EAdvancedFilterComparator;
  dateNumberComparatorOptions = clone(dateNumberComparatorOptions);
  formGroup: FormGroup;

  ngOnInit(): void {
    this.initForm();
    this.patchFormValue();
    this.updateFilterDataOnFormValuesChange();
  }

  setControlRequired(formControl: FormControl) {
    formControl.setValidators([Validators.required]);
    formControl.updateValueAndValidity({ emitEvent: false });
  }

  unsetControlRequired(formControl: FormControl) {
    formControl.removeValidators([Validators.required]);
    formControl.updateValueAndValidity({ emitEvent: false });
  }

  private updateFilterData(data: IAdvancedFilterData) {
    this.store$.dispatch(
      ApiCallStateActions.updateRequestPartDataSlice({
        apiCallStateKey: this.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.FILTERS,
        dataSliceKey: this.filterData.id,
        data: { advancedFilterData: data },
        shouldReplaceData: false,
      })
    );
  }

  private patchFormValue() {
    if (this.filterData?.advancedFilterData) {
      this.formGroup.patchValue(this.filterData?.advancedFilterData.formData);
    }
  }

  private updateFilterDataOnFormValuesChange() {
    this.subscription.add(
      this.formGroup.valueChanges.pipe(debounce(() => timer(50))).subscribe(values => {
        this.onFormValuesChange(values);

        const advFilterData: IAdvancedFilterData = {
          formData: values,
          isValid: this.formGroup.valid,
          value: null,
          complexValue: null,
        };

        this.updateFilterData({ ...advFilterData, ...this.getFilterValues() });
      })
    );
  }
}
