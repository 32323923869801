import { IBadgeConfig } from '@interfaces';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'aup-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() config: IBadgeConfig;
}
