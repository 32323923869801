import { EAttachedDocumentType } from '@/src/app/core/enums';
import { IApiFilter } from '@/src/app/core/interfaces';
import { marker } from '@ngneat/transloco-keys-manager/marker';
import { SearchType } from 'filter-data';

export const docCollFilterDefinition: IApiFilter = {
  id: 'docColl',
  property: 'linkedDocumentsType',
  value: null,
  defaultValue: null,
  defaultValues: null,
  comparator: SearchType.EQ,
  doNotSendWhenDefault: true,
  ui: {
    default: {
      type: 'multiple',
      options: [
        {
          value: EAttachedDocumentType.INVOICE,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.Fattura'),
        },
        {
          value: EAttachedDocumentType.FTNE,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.FTNE'),
        },
        {
          value: EAttachedDocumentType.NDC,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.NDC'),
        },
        {
          value: EAttachedDocumentType.NDCNE,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.NDCNE'),
        },
        {
          value: EAttachedDocumentType.PRV,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.PRV'),
        },
        {
          value: EAttachedDocumentType.DDT,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.DDT'),
        },
        {
          value: EAttachedDocumentType.DRF_FT,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.DRF_FT'),
        },
        {
          value: EAttachedDocumentType.FPR,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.FPR'),
        },
        {
          value: EAttachedDocumentType.NSO,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.NSO'),
        },
        {
          value: EAttachedDocumentType.ODA,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.ODA'),
        },
        {
          value: EAttachedDocumentType.ODV,
          label: marker('INVOICES.ATTACHED_DOC_TYPE.ODV'),
        },
      ],
    },
  },
  chips: {
    label: '',
    show: false,
  },
};
