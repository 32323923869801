import { EInvoiceFileType } from '@/src/app/core/enums';
import { IApiFilter } from '@/src/app/core/interfaces';
import { marker } from '@ngneat/transloco-keys-manager/marker';
import { SearchType } from 'filter-data';

export const fileTypeFilterDefinition: IApiFilter = {
  id: 'fileType',
  property: 'fileType',
  value: null,
  defaultValue: null,
  defaultValues: null,
  comparator: SearchType.EQ,
  doNotSendWhenDefault: true,
  ui: {
    default: {
      type: 'multiple',
      options: [
        {
          value: EInvoiceFileType.STANDARD_INVOICE,
          label: marker('INVOICES.FILE_TYPE.T1'),
        },
        {
          value: EInvoiceFileType.BATCH_OF_INVOICES,
          label: marker('INVOICES.FILE_TYPE.T2'),
        },
        {
          value: EInvoiceFileType.SIMPLIFIED_INVOICE,
          label: marker('INVOICES.FILE_TYPE.T3'),
        },
      ],
    },
  },
  chips: {
    label: '',
    show: false,
  },
};
