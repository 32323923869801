import { marker } from '@ngneat/transloco-keys-manager/marker';
import { EApiCallStateKey, EApiRequestPartKeys, EApiRequestPartsNames } from '../api-call-state.enum';
import { IApiCallState } from '../api-call-state.interface';
import { SearchType } from 'filter-data';
import { EReceivedInvoicePaymentStatus, EReceivedInvoiceStatus } from '../../../enums';
import { IApiFiltersList } from '../../../interfaces/filters.interface';
import clone from 'just-clone';

import { fileTypeFilterDefinition } from '@/src/app/modules/invoices/data/file-type.filter';
import { docCollFilterDefinition } from '@/src/app/modules/invoices/data/attached-document-type.filter';

export const receivedInvoicesApiCallState: IApiCallState = {
  key: EApiCallStateKey.RECEIVED_INVOICES,
  filtersUpdated: false,
  requestParts: [
    {
      key: EApiRequestPartKeys.PAGINATION,
      partName: EApiRequestPartsNames.PAGINATION,
      data: {
        page: 0,
        size: 10,
        totalItems: 0,
      },
    },
    {
      key: EApiRequestPartKeys.SORTING,
      partName: EApiRequestPartsNames.SORTING,
      data: {},
    },
    {
      key: EApiRequestPartKeys.FILTERS,
      partName: EApiRequestPartsNames.FILTER,
      data: {
        workedUsers: {
          id: 'workedUsers',
          property: 'workedUsers',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
        },
        fiscalYear: {
          id: 'fiscalYear',
          property: 'fiscalYear',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
        },
        date: {
          id: 'date',
          property: 'date',
          value: null,
          defaultValue: null,
          complexValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'date',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'UgualeA',
            value: null,
          },
        },
        receivingDate: {
          id: 'receivingDate',
          property: 'receivingDate',
          value: null,
          defaultValue: null,
          complexValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'date',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'UgualeA',
            value: null,
          },
        },
        number: {
          id: 'number',
          property: 'number',
          value: null,
          defaultValue: null,
          complexValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'Contiene',
            value: '',
          },
        },
        sender: {
          id: 'sender',
          property: 'sender',
          value: null,
          complexValue: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'Contiene',
            value: '',
          },
        },
        sdiFileName: {
          id: 'sdiFileName',
          property: 'sdiFileName',
          value: null,
          complexValue: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'Contiene',
            value: '',
          },
        },
        docColl: clone(docCollFilterDefinition),
        keyword: {
          id: 'keyword',
          property: 'keyword',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
        },
        fileType: clone(fileTypeFilterDefinition),
        status: {
          id: 'status',
          property: 'status',
          doNotSendWhenDefault: true,
          value: [EReceivedInvoiceStatus.NOT_READ, EReceivedInvoiceStatus.READ],
          defaultValue: [EReceivedInvoiceStatus.NOT_READ, EReceivedInvoiceStatus.READ],
          defaultValues: [[EReceivedInvoiceStatus.NOT_READ, EReceivedInvoiceStatus.READ]],
          comparator: SearchType.EQ,
          ui: {
            buttons: {
              type: 'buttons-multiple',
              options: [
                {
                  value: [EReceivedInvoiceStatus.NOT_READ, EReceivedInvoiceStatus.READ],
                  label: marker('RECEIVED_INVOICES.FILTERS.STATUS.SHOW_ALL'),
                },
                {
                  value: [EReceivedInvoiceStatus.NOT_READ],
                  label: marker('RECEIVED_INVOICES.FILTERS.STATUS.SHOW_NOT_READ'),
                },
              ],
            },
            multiple: {
              type: 'multiple',
              options: [
                {
                  value: EReceivedInvoiceStatus.READ,
                  label: marker('RECEIVED_INVOICES.STATUS.READ'),
                },
                {
                  value: EReceivedInvoiceStatus.NOT_READ,
                  label: marker('RECEIVED_INVOICES.STATUS.NOT_READ'),
                },
              ],
            },
          },
          chips: {
            label: '',
            show: false,
            type: 'options',
            options: [
              {
                value: EReceivedInvoiceStatus.READ,
                label: marker('RECEIVED_INVOICES.STATUS.READ'),
              },
              {
                value: EReceivedInvoiceStatus.NOT_READ,
                label: marker('RECEIVED_INVOICES.STATUS.NOT_READ'),
              },
            ],
          },
        },
        paymentStatus: {
          id: 'paymentStatus',
          property: 'collectionPaymentStatus',
          doNotSendWhenDefault: true,
          value: [
            EReceivedInvoicePaymentStatus.PAID,
            EReceivedInvoicePaymentStatus.UNPAID,
            EReceivedInvoicePaymentStatus.UNMANAGED,
            EReceivedInvoicePaymentStatus.PARTIALLY_PAID,
            EReceivedInvoicePaymentStatus.REVERSED,
          ],
          defaultValue: [
            EReceivedInvoicePaymentStatus.PAID,
            EReceivedInvoicePaymentStatus.UNPAID,
            EReceivedInvoicePaymentStatus.UNMANAGED,
            EReceivedInvoicePaymentStatus.PARTIALLY_PAID,
            EReceivedInvoicePaymentStatus.REVERSED,
          ],
          defaultValues: [
            [
              EReceivedInvoicePaymentStatus.PAID,
              EReceivedInvoicePaymentStatus.UNPAID,
              EReceivedInvoicePaymentStatus.UNMANAGED,
              EReceivedInvoicePaymentStatus.PARTIALLY_PAID,
              EReceivedInvoicePaymentStatus.REVERSED,
            ],
          ],
          comparator: SearchType.EQ,
          ui: {
            multiple: {
              type: 'multiple',
              options: [
                {
                  value: EReceivedInvoicePaymentStatus.PAID,
                  label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.PAID'),
                },
                {
                  value: EReceivedInvoicePaymentStatus.UNPAID,
                  label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.UNPAID'),
                },
                {
                  value: EReceivedInvoicePaymentStatus.UNMANAGED,
                  label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.UNMANAGED'),
                },
                {
                  value: EReceivedInvoicePaymentStatus.PARTIALLY_PAID,
                  label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.PARTIALLY_PAID'),
                },
                {
                  value: EReceivedInvoicePaymentStatus.REVERSED,
                  label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.REVERSED'),
                },
              ],
            },
          },
          chips: {
            label: '',
            show: false,
            type: 'options',
            options: [
              {
                value: EReceivedInvoicePaymentStatus.PAID,
                label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.PAID'),
              },
              {
                value: EReceivedInvoicePaymentStatus.UNPAID,
                label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.UNPAID'),
              },
              {
                value: EReceivedInvoicePaymentStatus.UNMANAGED,
                label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.UNMANAGED'),
              },
              {
                value: EReceivedInvoicePaymentStatus.PARTIALLY_PAID,
                label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.PARTIALLY_PAID'),
              },
              {
                value: EReceivedInvoicePaymentStatus.REVERSED,
                label: marker('RECEIVED_INVOICES.PAYMENT_STATUS.REVERSED'),
              },
            ],
          },
        },
      } as IApiFiltersList,
    },
  ],
};
