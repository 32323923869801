import { Pipe, PipeTransform } from '@angular/core';
import { ReceivedInvoiceListItem, SentInvoiceListItem } from '@models';
import { EDocumentType } from '@enums';

@Pipe({
  name: 'isNonElectronic',
})
export class IsNonElectronicPipe implements PipeTransform {
  transform(invoice: ReceivedInvoiceListItem | SentInvoiceListItem): boolean {
    return invoice.type === EDocumentType.ARU_TD01 || invoice.type === EDocumentType.ARU_TD04;
  }
}
