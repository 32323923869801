import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReceivedInvoiceListItem, SentInvoiceListItem } from '@models';
import { EReceivedInvoiceAction, EReceivedInvoiceStatus, ESentInvoiceAction } from '@enums';
import { IsNonElectronicPipe } from '@shared/pipe/is-non-electronic.pipe';

@Directive({
  selector: '[aupPermittedInvoiceActions]',
})
export class PermittedInvoiceActionsDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private isNonElectronicPipe: IsNonElectronicPipe
  ) {}

  @Input()
  set aupPermittedInvoiceActions(params: {
    invoice: ReceivedInvoiceListItem | SentInvoiceListItem;
    action: ESentInvoiceAction | EReceivedInvoiceAction;
    isSentInvoice: boolean;
  }) {
    this.viewContainer.clear();
    const isNonElectronic = this.isNonElectronicPipe.transform(params?.invoice);

    let show: boolean;
    if (isNonElectronic) {
      show = this.permitedNonEletronicActions(params?.action);
    } else {
      show = this.permitedEletronicActions(params?.action, params?.invoice, params?.isSentInvoice);
    }

    if (show) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private permitedEletronicActions(
    action: ESentInvoiceAction | EReceivedInvoiceAction,
    invoice: ReceivedInvoiceListItem | SentInvoiceListItem,
    isSentInvoice: boolean
  ): boolean {
    switch (action) {
      case EReceivedInvoiceAction.DOWNLOAD_SAVED_FILES:
        if (isSentInvoice) {
          return !!invoice?.attached;
        } else {
          return !!invoice?.attachedDocuments?.length;
        }
      case EReceivedInvoiceAction.DOWNLOAD_P7M:
        return invoice.canDownloadP7M;
      case EReceivedInvoiceAction.MARK_AS_READ:
        return invoice?.status === EReceivedInvoiceStatus.NOT_READ;
      case EReceivedInvoiceAction.MARK_AS_UNREAD:
        return invoice?.status === EReceivedInvoiceStatus.READ;
      default:
        return true;
    }
  }

  private permitedNonEletronicActions(action: ESentInvoiceAction | EReceivedInvoiceAction): boolean {
    return action === 'DOWNLOAD_PDF' || action === 'PRINT';
  }
}
