import { EReceivedInvoiceStatus, ESentInvoiceStatus } from '../enums';
import {
  IInvoiceAttachments,
  IInvoiceCarTaxData,
  IInvoiceCorrelatedDocuments,
  IInvoiceCustomerEmail,
  IInvoiceDeduction,
  IInvoiceElaborations,
  IInvoiceFile,
  IInvoiceGeneralData,
  IInvoiceIncreaseSale,
  IInvoicePaRecipient,
  IInvoicePayment,
  IInvoicePdfOption,
  IInvoicePrivateRecipient,
  IInvoiceProduct,
  IInvoiceReason,
  IInvoiceRelatedDocument,
  IInvoiceRounding,
  IInvoiceSal,
  IInvoiceSdiLayoutCompatibility,
  IInvoiceSocialSecurityTreasury,
  IInvoiceSummaryItem,
  IInvoiceSupplierRecipient,
  IInvoiceTransportData,
  IInvoiceVatChargeability,
  IInvoiceVehicleData,
  IInvoiceXmlConsigneeClient,
  IInvoiceXmlElaborations,
  IInvoiceXmlFiscalRepresentative,
  IInvoiceXmlPaymentData,
  IInvoiceXmlSupplierServiceProvider,
  IInvoiceXmlTrasmissionData,
  IMainInvoice,
  IRecurringInvoiceData,
} from '../interfaces';

import { InvoiceDocument } from './invoice-document.model';
import { SuperModel } from './super-model.model';
import * as moment from 'moment';

export class InvoiceFull extends SuperModel {
  username: string;
  status: ESentInvoiceStatus | EReceivedInvoiceStatus;
  uploadFileName: string;
  file: IInvoiceFile;
  document: InvoiceDocument;
  generalData: IInvoiceGeneralData;
  socialSecurityTreasury: IInvoiceSocialSecurityTreasury[];
  deduction: IInvoiceDeduction[];
  vatChargeability: IInvoiceVatChargeability;
  correlatedDocuments: IInvoiceCorrelatedDocuments;
  attachments: IInvoiceAttachments;
  carTaxData: IInvoiceCarTaxData;
  reason: IInvoiceReason;
  article73Active: boolean;
  rounding: IInvoiceRounding;
  increaseSale: IInvoiceIncreaseSale;
  mainInvoice: IMainInvoice;
  sal: IInvoiceSal;
  vehicleData: IInvoiceVehicleData;
  transportData: IInvoiceTransportData;
  elaborations: IInvoiceElaborations;
  xmlElaborations: IInvoiceXmlElaborations;
  paRecipient: IInvoicePaRecipient;
  privateRecipient: IInvoicePrivateRecipient;
  supplierRecipient: IInvoiceSupplierRecipient;
  products: IInvoiceProduct[];
  payment: IInvoicePayment;
  typeEditor: string;
  step: number;
  customerEmail: IInvoiceCustomerEmail;
  paymentId: string;
  summaryData: IInvoiceSummaryItem[];
  xmlTrasmissionData: IInvoiceXmlTrasmissionData;
  xmlSupplierServiceProvider: IInvoiceXmlSupplierServiceProvider;
  xmlConsigneeClient: IInvoiceXmlConsigneeClient;
  xmlFiscalRepresentative: IInvoiceXmlFiscalRepresentative;
  xmlThirdIntermediary: IInvoiceXmlFiscalRepresentative;
  xmlSummaryData: IInvoiceSummaryItem[];
  xmlPaymentData: IInvoiceXmlPaymentData;
  sentReceivedInvoice: string;
  receivedSdiSentDate: Date;
  issuerSubject: string;
  logoReferenceId: string;
  pdfOption: IInvoicePdfOption;
  collectionPaymentStatus: number;
  collectionPaymentDate: Date;
  installmentStatusIcon: number;
  netAmountManualPayment: number;
  xsdFormat: number;
  preserved: boolean;
  sdiLayoutCompatibility: IInvoiceSdiLayoutCompatibility;
  primaryCode: string;
  secondaryCode: string;
  registryAssociation: string;
  creditNoteAssociation: string;
  creditNoteAssociationPosition: number;
  associatedCreditNoteNumber: number;
  associatedCreditNoteImport: number;
  businessVolume: number;
  selfInvoice: boolean;
  enableCreationCreditNote: boolean;
  consolidated: boolean;
  relatedDocuments: IInvoiceRelatedDocument[];
  shop: string;
  groupChannel: number;
  channel: string;
  recurringInvoiceData: IRecurringInvoiceData;
  position: number;

  constructor(data: any) {
    super(data);

    this.mapDates(data);
    this.mapModels(data);
  }

  get canDownloadP7M() {
    return this.uploadFileName.includes('p7m');
  }

  private mapModels(data: any) {
    if (data.document) {
      this.document = new InvoiceDocument(data.document);
    }
  }

  private mapDates(data: any) {
    if (data.collectionPaymentDate) {
      this.collectionPaymentDate = moment(data.collectionPaymentDate).toDate();
    }

    if (data.correlatedDocuments?.details?.length) {
      this.correlatedDocuments.details = data.correlatedDocuments.details.map(detail => {
        return {
          ...detail,
          date: moment(detail.date).toDate(),
        };
      });
    }

    if (data.mainInvoice?.date) {
      this.mainInvoice.date = moment(data.mainInvoice.date).toDate();
    }

    if (data.transportData?.pickupDateTime) {
      this.transportData.pickupDateTime = moment(data.transportData.pickupDateTime).toDate();
    }

    if (data.transportData?.transportStartDateTime) {
      this.transportData.transportStartDateTime = moment(data.transportData.transportStartDateTime).toDate();
    }

    if (data.transportData?.deliveryDateTime) {
      this.transportData.deliveryDateTime = moment(data.transportData.deliveryDateTime).toDate();
    }

    if (data.privateRecipient?.intent?.telematicReceiptDate) {
      this.privateRecipient.intent.telematicReceiptDate = moment(
        data.privateRecipient.intent.telematicReceiptDate
      ).toDate();
    }

    if (data.payment?.installments?.length) {
      this.payment.installments = data.payment?.installments.map(item => {
        const date = item.date ? moment(item.date).toDate() : null;
        const penaltyStartDate = item.penaltyStartDate ? moment(item.penaltyStartDate).toDate() : null;
        return {
          ...item,
          date,
          penaltyStartDate,
        };
      });
    }

    if (data.payment?.collectionPaymentDate) {
      this.payment.collectionPaymentDate = moment(data.payment.collectionPaymentDate).toDate();
    }

    if (data.relatedDocuments?.length) {
      this.relatedDocuments = data.relatedDocuments.map(document => {
        const sentDate = document.sentDate ? moment(document.sentDate).toDate() : null;
        const documentDate = document.documentDate ? moment(document.documentDate).toDate() : null;

        return {
          document,
          sentDate,
          documentDate,
        };
      });
    }

    if (data.receivedSdiSentDate) {
      this.receivedSdiSentDate = moment(data.receivedSdiSentDate).toDate();
    }
  }

  override get serialised() {
    return {};
  }
}
