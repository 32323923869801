import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppState } from '../../state';
import { Store } from '@ngrx/store';
import { selectBreakpoints, selectUser } from '../../state/session/session.selectors';
import { AppConfigService, AuthService, NewsService } from '@services';
import { Observable, Subscription, map, tap } from 'rxjs';
import { User } from '../../models';
import { MatSidenav } from '@angular/material/sidenav';
import { IConfig } from '../../interfaces';
import { Router } from '@angular/router';
import { ECustomMenu } from '../../enums';

/**
 * LayoutComponent
 *
 * @export
 * @class LayoutComponent
 * @typedef {LayoutComponent}
 */
@Component({
  selector: 'aup-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: MatSidenav;

  /**
   * Breakpoints observable
   * @type {*}
   */
  breakpoints$ = this.store.select(selectBreakpoints);

  isMobile = false;
  /**
   * Current logged user
   *
   * @type {Observable<User>}
   */
  user$: Observable<User>;

  externalLinks = {
    changePassword: null,
    manageAccount: null,
    assistance: 'https://assistenza.aruba.it/home.aspx',
    guides: 'https://guide.pec.it/supervisore360.aspx',
  };

  isMenuExpanded = true;
  isHandsetLayout;
  customMenu: ECustomMenu = null;
  eCustomMenu = ECustomMenu;

  subscription: Subscription = new Subscription();
  public appConfig: IConfig;

  /**
   * Creates an instance of LayoutComponent.
   *
   * @constructor
   * @param {Store<AppState>} store
   * @param {AuthService} authService
   * @param {AppConfigService} appConfigService
   * @param {Router} router
   */
  constructor(
    private readonly store: Store<AppState>,
    private readonly authService: AuthService,
    private readonly appConfigService: AppConfigService,
    private readonly router: Router,
    private readonly newsService: NewsService
  ) {
    this.user$ = this.store.select(selectUser);
    this.appConfig = this.appConfigService.getConfig();
    this.externalLinks.changePassword = this.appConfig.accountManagementUrl + '/security/changepassword';
    this.externalLinks.manageAccount = this.appConfig.accountManagementUrl;
  }

  ngOnInit(): void {
    this.store
      .select(selectBreakpoints)
      .pipe(
        map(breakpoints => breakpoints.isXSmall),
        tap(isMobile => (this.isMobile = isMobile))
      )
      .subscribe();

    this.store.select(selectBreakpoints).subscribe(breakpoints => {
      this.isHandsetLayout = breakpoints.isHandset;
    });

    this.listenForCustomMenus();
    this.user$.subscribe(user => {
      //@ts-ignore
      window.usabilla_live('data', {
        custom: {
          user_created_at: user.createdAt,
          pec_delegations_number: user.pecDelegationsNumber,
          fel_delegations_number: user.felDelegationsNumber,
          '2fa_status': user.status2fa,
        },
      });

      console.info('User data sent to Usabilla');
    });
  }

  onSuggestionClick() {
    const button = document.querySelector('.usabilla-live-button') as HTMLIFrameElement;
    button.contentWindow.document.querySelector('html').click();
    this.closeDrawerIfMobile();
  }

  closeDrawerIfMobile() {
    if (this.isMobile) {
      this.drawer.close();
    }
  }
  /**
   * Logout user
   */
  logout() {
    this.newsService.resetDisplayedSuperNewsIds();
    this.authService.logout();
  }

  onCustomMenuOpen() {
    setTimeout(() => {
      this.drawer.open();
    });
  }

  private listenForCustomMenus() {
    this.subscription.add(
      this.router.events.subscribe(event => {
        let route = this.router.routerState.snapshot.root;

        while (route.firstChild) {
          if (route.firstChild.data['menu']) {
            this.setCustomMenu(route.firstChild.data['menu']);
            return;
          }
          route = route.firstChild;
        }
        this.setCustomMenu(undefined);
      })
    );
  }

  private setCustomMenu(menu: ECustomMenu | undefined) {
    this.customMenu = menu;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
