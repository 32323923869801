import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import clone from 'just-clone';
import { IColumnsConfig, IColumn } from '@interfaces';

@Component({
  selector: 'aup-columns-manager',
  templateUrl: './columns-manager.component.html',
  styleUrls: ['./columns-manager.component.scss'],
})
export class ColumnsManagerComponent {
  @Input() set columnsConfig(columnsConfig: IColumnsConfig) {
    this._columnsConfig = clone(columnsConfig);
  }
  @Input() defaultColumnsConfig: IColumnsConfig;
  @Input() staticColumnsStart: IColumn[];
  @Input() staticColumnsEnd: IColumn[];

  @Output() columnsChange: EventEmitter<IColumnsConfig> = new EventEmitter<IColumnsConfig>();

  _columnsConfig: IColumnsConfig;

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this._columnsConfig.columns, event.previousIndex, event.currentIndex);
  }

  onCheckboxClick(checked: boolean, column: IColumn, event: Event) {
    event.stopPropagation();
    column.checked = checked;
  }

  onSave() {
    const output: IColumnsConfig = [
      this.staticColumnsStart,
      this._columnsConfig.columns,
      this.staticColumnsEnd,
    ].reduce(
      (prev: IColumnsConfig, current) => {
        const tempOutput = this.getColumnsAndFiltersKeys(current);
        prev.columnsKeys.push(...tempOutput.columnsKeys);
        prev.filterColumnsKeys.push(...tempOutput.filterColumnsKeys);
        return prev;
      },
      { columnsKeys: [], filterColumnsKeys: [], columns: [] }
    );

    this.columnsChange.emit({ ...output, columns: clone(this._columnsConfig.columns) });
  }

  onReset($event: Event) {
    $event.stopPropagation();
    this._columnsConfig = clone(this.defaultColumnsConfig);
  }

  private getColumnsAndFiltersKeys(columns: IColumn[]): Partial<IColumnsConfig> {
    return columns.reduce(
      (prev: Partial<IColumnsConfig>, current) => {
        if (current.checked) {
          prev.columnsKeys.push(current.key);
          prev.filterColumnsKeys.push(current.filterKey);
        }
        return prev;
      },
      { columnsKeys: [], filterColumnsKeys: [] }
    );
  }
}
