import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * SettingsMenuComponent
 *
 * @export
 * @class SettingsMenuComponent
 * @typedef {SettingsMenuComponent}
 */
@Component({
  selector: 'aup-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
})
export class SettingsMenuComponent {
  /**
   * Flag describe if menu is expanded
   *
   * @type {boolean}
   */
  @Input() isMenuExpanded: boolean;
  /**
   * Flag describe if it is mobile browser
   *
   * @type {boolean}
   */
  @Input() isMobile: boolean;
  /**
   * Emit event on menu position click
   *
   * @type {EventEmitter<void>}
   */
  @Output() closeDrawerIfMobile: EventEmitter<void> = new EventEmitter();
}
