import { AppState } from '@/src/app/core/state';
import { selectBreakpoints } from '@/src/app/core/state/session/session.selectors';
import { IBreakpoints } from '@/src/app/core/state/session/session.state';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'aup-master-detail',
  templateUrl: './master-detail.component.html',
  styleUrls: ['./master-detail.component.scss'],
})
export class MasterDetailComponent {
  @Input() selectedItem: boolean;
  @Input() detailFullWidth = true;

  @Output() close = new EventEmitter();
  isDetailExpanded = false;
  $breakpoints: Observable<IBreakpoints>;

  constructor(private store: Store<AppState>) {
    this.$breakpoints = this.store.select(selectBreakpoints);
  }

  onExpand() {
    this.isDetailExpanded = !this.isDetailExpanded;
  }

  onClose() {
    this.close.emit();
  }
}
