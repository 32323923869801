import { AfterContentInit, ContentChild, Directive, OnDestroy } from '@angular/core';
import { FormControlName } from '@angular/forms';
import { MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: 'mat-date-range-input',
})
export class MatRangeValidationFixDirective implements AfterContentInit, OnDestroy {
  @ContentChild(MatStartDate, { read: FormControlName }) startDateControlName: FormControlName;
  @ContentChild(MatEndDate, { read: FormControlName }) endDateControlName: FormControlName;

  private readonly destroyed$ = new Subject<void>();

  ngAfterContentInit(): void {
    if (!this.startDateControlName || !this.endDateControlName) {
      return;
    }

    this.startDateControlName.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe(() => setTimeout(() => this.endDateControlName.control.updateValueAndValidity()));

    this.endDateControlName.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))

      .subscribe(() => setTimeout(() => this.startDateControlName.control.updateValueAndValidity()));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
