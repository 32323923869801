import * as moment from 'moment';
import { SuperModel } from './super-model.model';
import { EAttachedDocumentType } from '../enums';

export class AttachedDocument extends SuperModel {
  type: EAttachedDocumentType;
  idRef: string;
  position: number;
  number: string;
  documentDate: Date;

  constructor(data: any) {
    super(data);

    if (data.documentDate) {
      this.documentDate = moment(data.documentDate).toDate();
    }
  }

  get serialised() {
    return {};
  }
}
