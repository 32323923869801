import { Component, Inject, Injector, OnInit } from '@angular/core';
import { SuperDialogComponent } from '../super-dialog/super-dialog.component';
import { IAdvancedFiltersConfig, IApiFilter, IDialogResult, IDialogSizeMap } from '@/src/app/core/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiCallStateActions } from '@/src/app/core/state/api-call-state/api-call-state.actions';
import { EApiRequestPartKeys } from '@/src/app/core/state/api-call-state/api-call-state.enum';
import { Observable, take, tap } from 'rxjs';
import { selectAdvancedFilters } from '@/src/app/core/state/api-call-state/api-call-state.selectors';
import { EDialogCloseStatus } from '@/src/app/core/enums';

@Component({
  selector: 'aup-advanced-filters',
  templateUrl: './advanced-filters.component.html',
  styleUrls: ['./advanced-filters.component.scss'],
})
export class AdvancedFiltersComponent
  extends SuperDialogComponent<AdvancedFiltersComponent>
  implements OnInit
{
  /**
   * @inheritdoc
   *
   * @type {string}
   */
  override content: string = 'default';

  /**
   * Dialog sizing map depend on content/screen size
   *
   * @type {IDialogSizeMap}
   */
  sizesMap: IDialogSizeMap = {
    default: {
      small: {
        width: '100vw',
        height: 'auto',
      },
      other: {
        width: '770px',
        height: 'auto',
      },
    },
  };

  filters$: Observable<IApiFilter[]>;
  selectedFiltersProperties: string[] = [];
  numberOfFilters: number;

  displayValidationErrors: number = null;

  /**
   * Creates an instance of AdvancedFiltersComponent.
   *
   * @constructor
   * @param {Injector} injector
   * @param {MatDialogRef<AdvancedFiltersComponent, IDialogResult>} dialogRef
   */
  constructor(
    override injector: Injector,
    override dialogRef: MatDialogRef<AdvancedFiltersComponent, IDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public config: IAdvancedFiltersConfig
  ) {
    super(injector, dialogRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.filters$ = this.store$
      .select(selectAdvancedFilters({ apiCallStateKey: this.config.apiCallStateKey }))
      .pipe(
        tap(filters => {
          this.selectedFiltersProperties = filters.map(filter => filter.property);
          this.numberOfFilters = filters.length;

          if (!this.numberOfFilters) {
            setTimeout(() => this.onAddFilter());
          }
        })
      );
  }

  onFilter() {
    this.filters$
      .pipe(
        take(1),
        tap(filters => {
          if (!this.areAllFiltersValid(filters)) {
            this.displayValidationErrors = Date.now();
            setTimeout(() => (this.displayValidationErrors = null), 50);
            return;
          }
          this.applyFilters(filters);
          this.dialogRef.close({ status: EDialogCloseStatus.OK });
        })
      )
      .subscribe();
  }

  onAddFilter() {
    const id = 'custom_filter_' + Date.now();
    this.store$.dispatch(
      ApiCallStateActions.updateRequestPart({
        apiCallStateKey: this.config.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.FILTERS,
        data: {
          [id]: {
            id,
            isAdvancedFilter: true,
            value: null,
            complexValue: null,
            defaultValue: null,
            advancedFilterData: {
              formData: null,
              isValid: false,
              value: null,
              complexValue: null,
            },
          } as Partial<IApiFilter>,
        },
        shouldReplaceData: false,
      })
    );
  }

  trackByFn(index, filter: IApiFilter): string {
    return filter.id;
  }

  private areAllFiltersValid(filters: IApiFilter[]) {
    return !filters.some(filter => !filter.advancedFilterData.isValid);
  }

  private applyFilters(filters: IApiFilter[]) {
    this.store$.dispatch(
      ApiCallStateActions.resetAllFilters({
        apiCallStateKey: this.config.apiCallStateKey,
        removeAdvancedFilters: false,
      })
    );

    this.store$.dispatch(
      ApiCallStateActions.resetPagination({
        apiCallStateKey: this.config.apiCallStateKey,
      })
    );

    filters.forEach(filter => {
      const { advancedFilterData } = filter;
      const dataToUpdate = {
        value: advancedFilterData.value,
        complexValue: advancedFilterData.complexValue,
      };
      this.store$.dispatch(
        ApiCallStateActions.updateRequestPartDataSlice({
          apiCallStateKey: this.config.apiCallStateKey,
          requestPartKey: EApiRequestPartKeys.FILTERS,
          dataSliceKey: filter.id,
          data: dataToUpdate,
        })
      );
    });
    this.store$.dispatch(ApiCallStateActions.load({ apiCallStateKey: this.config.apiCallStateKey }));
  }
}
