import { marker } from '@ngneat/transloco-keys-manager/marker';
import { EApiCallStateKey, EApiRequestPartKeys, EApiRequestPartsNames } from '../api-call-state.enum';
import { IApiCallState } from '../api-call-state.interface';
import { SearchType } from 'filter-data';
import { EApiSortDirection, EPecStatus } from '../../../enums';
import { IApiFiltersList } from '../../../interfaces/filters.interface';

export const pecApiCallState: IApiCallState = {
  key: EApiCallStateKey.PEC,
  filtersUpdated: false,
  requestParts: [
    {
      key: EApiRequestPartKeys.PAGINATION,
      partName: EApiRequestPartsNames.PAGINATION,
      data: {
        page: 0,
        size: 10,
        totalItems: 0,
      },
    },
    {
      key: EApiRequestPartKeys.SORTING,
      partName: EApiRequestPartsNames.SORTING,
      data: { property: 'messagesToRead', direction: EApiSortDirection.DESC },
    },
    {
      key: EApiRequestPartKeys.FILTERS,
      partName: EApiRequestPartsNames.FILTER,
      data: {
        user: {
          id: 'user',
          property: 'user',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker('PEC.FILTERS.CHIP_LABEL.USER'),
            show: true,
            type: 'value',
          },
        },
        name: {
          id: 'name',
          property: 'name',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker('PEC.FILTERS.CHIP_LABEL.NAME'),
            show: true,
            type: 'value',
          },
        },
        messagesToRead: {
          id: 'messagesToRead',
          property: 'messagesToRead',
          value: 'false',
          defaultValue: 'false',
          defaultValues: ['false'],
          comparator: SearchType.EQ,
          doNotSendWhenDefault: true,
          ui: {
            default: {
              type: 'dropdown',
              options: [{ label: marker('PEC.FILTERS.MESSAGES_TO_READ_OPTIONS.TO_READ'), value: 'true' }],
            },
          },
          chips: {
            show: false,
          },
        },
        status: {
          id: 'status',
          property: 'status',
          value: [EPecStatus.TO_ACCEPT, EPecStatus.WAITING, EPecStatus.DELEGATED],
          defaultValue: [EPecStatus.TO_ACCEPT, EPecStatus.WAITING, EPecStatus.DELEGATED],
          defaultValues: [
            [EPecStatus.TO_ACCEPT, EPecStatus.WAITING, EPecStatus.DELEGATED],
            [
              EPecStatus.DELEGATED,
              EPecStatus.WAITING,
              EPecStatus.REFUSED,
              EPecStatus.REVOKED,
              EPecStatus.SUSPENDED,
              EPecStatus.TO_ACCEPT,
              EPecStatus.SUSPENDED_UPGRADE_REQUIRED,
            ],
          ],
          comparator: SearchType.EQ,
          ui: {
            buttons: {
              type: 'buttons-multiple',
              options: [
                {
                  value: [EPecStatus.TO_ACCEPT, EPecStatus.WAITING, EPecStatus.DELEGATED],
                  label: marker('PEC.FILTER_OPTIONS.SHOW_ACTIVE'),
                },
                {
                  value: [
                    EPecStatus.DELEGATED,
                    EPecStatus.WAITING,
                    EPecStatus.REFUSED,
                    EPecStatus.REVOKED,
                    EPecStatus.SUSPENDED,
                    EPecStatus.TO_ACCEPT,
                    EPecStatus.SUSPENDED_UPGRADE_REQUIRED,
                  ],
                  label: marker('PEC.FILTER_OPTIONS.SHOW_ALL'),
                },
              ],
            },
            multiple: {
              type: 'multiple',
              options: [
                {
                  value: EPecStatus.DELEGATED,
                  label: marker('PEC.STATUS.DELEGATED'),
                },
                {
                  value: EPecStatus.WAITING,
                  label: marker('PEC.STATUS.WAITING'),
                },
                {
                  value: EPecStatus.TO_ACCEPT,
                  label: marker('PEC.STATUS.TO_ACCEPT'),
                },
                {
                  value: EPecStatus.SUSPENDED_UPGRADE_REQUIRED,
                  label: marker('PEC.STATUS.SUSPENDED_UPGRADE_REQUIRED'),
                },
                {
                  value: EPecStatus.SUSPENDED,
                  label: marker('PEC.STATUS.SUSPENDED'),
                },
                {
                  value: EPecStatus.REFUSED,
                  label: marker('PEC.STATUS.REFUSED'),
                },
                {
                  value: EPecStatus.REVOKED,
                  label: marker('PEC.STATUS.REVOKED'),
                },
              ],
            },
          },
          chips: {
            label: '',
            show: false,
            type: 'options',
            options: [
              {
                value: EPecStatus.DELEGATED,
                label: marker('PEC.STATUS.DELEGATED'),
              },
              {
                value: EPecStatus.WAITING,
                label: marker('PEC.STATUS.WAITING'),
              },
              {
                value: EPecStatus.TO_ACCEPT,
                label: marker('PEC.STATUS.TO_ACCEPT'),
              },
              {
                value: EPecStatus.SUSPENDED,
                label: marker('PEC.STATUS.SUSPENDED'),
              },
              {
                value: EPecStatus.SUSPENDED_UPGRADE_REQUIRED,
                label: marker('PEC.STATUS.SUSPENDED_UPGRADE_REQUIRED'),
              },
              {
                value: EPecStatus.REFUSED,
                label: marker('PEC.STATUS.REFUSED'),
              },
              {
                value: EPecStatus.REVOKED,
                label: marker('PEC.STATUS.REVOKED'),
              },
            ],
          },
        },
      } as IApiFiltersList,
    },
  ],
};
