import { Component } from '@angular/core';
import { AdvancedFiltersFilterItemBase } from '../advanced-filters-filter-item-base/advanced-filters-filter-item-base.component';
import { Validators } from '@angular/forms';
import { IAdvancedFilterData } from '@interfaces';
import { textComparatorOptions } from '../../data';

@Component({
  selector: 'aup-advanced-filters-text',
  templateUrl: './advanced-filters-text.component.html',
  styleUrls: ['./advanced-filters-text.component.scss'],
})
export class AdvancedFiltersTextComponent extends AdvancedFiltersFilterItemBase {
  textComparatorOptions = textComparatorOptions;

  initForm() {
    this.formGroup = this.fb.group({
      type: [null, Validators.required],
      text: [null, Validators.required],
    });
  }

  getFilterValues(): Partial<IAdvancedFilterData> {
    if (!this.formGroup.valid) {
      return {};
    }

    const { type, text } = this.formGroup.value;

    return {
      value: null,
      complexValue: {
        type: type,
        value: text,
      },
    };
  }
}
