import { AppState } from '@/src/app/core/state';
import { Component, inject, Inject, Injector, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  template: '',
})
export abstract class AdvancedFiltersBase implements OnDestroy {
  store$: Store<AppState> = inject(Store<AppState>);

  subscription: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
