import { Component } from '@angular/core';
import { AdvancedFiltersFilterItemBase } from '../advanced-filters-filter-item-base/advanced-filters-filter-item-base.component';
import { FormControl, Validators } from '@angular/forms';
import { IAdvancedFilterData, IApiFilterOptionsGroup } from '@interfaces';

@Component({
  selector: 'aup-advanced-filters-select',
  templateUrl: './advanced-filters-select.component.html',
  styleUrls: ['./advanced-filters-select.component.scss'],
})
export class AdvancedFiltersSelectComponent extends AdvancedFiltersFilterItemBase {
  initForm() {
    this.formGroup = this.fb.group({
      values: [null, Validators.required],
    });
  }

  get formControl(): FormControl {
    return this.formGroup.get('values') as FormControl;
  }

  getFilterValues(): Partial<IAdvancedFilterData> {
    if (!this.formGroup.valid) {
      return {};
    }

    return {
      value: this.formControl.value,
      complexValue: null,
    };
  }

  getAllOptions(optionsGroups: IApiFilterOptionsGroup[]) {
    return optionsGroups.reduce((prev, current) => [...prev, ...current.options], []);
  }
}
